<template>
  <div style="position: relative" class="vide-list-container">
    <ClassCondition
      :reset="reset"
      :submit="submit"
      :visiable="visiable"
      :clickButton="clickButton"
      :activeStyle="activeStyle"
    />
    <div class="home-container">
      <div class="top-container">
        <div class="form-container" style="padding-right: 0px">
          <ClassInput :video_name="video_name" :onSubmit="onSubmit" />
        </div>
      </div>
      <ClassTab
        :fadeModal="fadeModal"
        :activeStyle="activeStyle"
        :activeClick="activeClick"
      />
      <div class="contents-container contents-container-other">
        <div class="class-container class-container-other">
          <mescroll-vue
            ref="mescroll"
            :down="mescrollDown"
            :up="mescrollUp"
            @init="mescrollInit"
            id="wrap-class-containers"
          >
            <div class="contents contents-other">
              <VideoListComponent
                :dataList="dataList"
                :toDetail="toDetail"
                :activeStyle="activeStyle"
                :toDetailOther="toDetailOther"
              />
            </div>
          </mescroll-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import classApi from "@/api/class";
import { clickList } from "../tools/config";
import ClassTab from "@/components/tabs/ClassTab";
import MescrollVue from "mescroll.js/mescroll.vue";
import ClassInput from "@/components/inputs/ClassInput";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import ClassCondition from "@/components/popups/ClassCondition";
import VideoListComponent from "@/components/videos/VideoListComponent";

export default {
  name: "Class",
  components: {
    ClassTab,
    MescrollVue,
    ClassInput,
    ClassCondition,
    VideoListComponent,
    "v-iconFontSymbol": IconFontSymbol,
  },
  data() {
    return {
      video_name: "",
      product_type: "",
      class_type: "",
      pay_type: "",
      clickArr: clickList,
      visiable: false,
      activeStyle: true,
      dataList: [],
      mescroll: null,
      mescrollDown: {
        use: false,
      },
      mescrollUp: {
        callback: this.upCallback,
        empty: {
          warpId: "wrap-class-containers",
          icon: require("../assets/search.png"),
          tip: "暂无相关数据~",
        },
        htmlNodata: '<p class="upwarp-nodata">-- 已经到最底部啦 --</p>',
        htmlLoading:
          '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">加载中...</p>',
        noMoreSize: 5,
        isBounce: true,
        page: {
          num: 0,
          video_count: 10,
        },
        toTop: {
          src: require("../assets/topButton.png"),
          offset: 500,
        },
      },
    };
  },
  beforeUnmount() {
    this.mescroll.hideTopBtn(0.1);
  },
  methods: {
    async subClicks(special_name) {
      await this.$request.handleFetchPostJson(classApi.clicks, {
        special_name: special_name,
      });
    },

    onSubmit() {
      const params = { video_name: this.video_name, page: 1 };
      if (this.product_type !== "") {
        params.product_type = this.product_type;
      }
      if (this.activeStyle == true) {
        if (this.class_type !== "") {
          params.class_type = this.class_type;
        }
      }
      if (this.pay_type !== "") {
        params.pay_type = this.pay_type;
      }
      this.mescrollUp.page.num = 1;
      this.dataList = [];
      this.getData(params, this.mescroll);
    },

    toDetail(video_id) {
      this.$router.push({
        path: "/hotDetail",
        name: "hotDetail",
        query: {
          isHot: this.activeStyle,
          video_id: video_id,
        },
      });
    },

    toDetailOther(special_name) {
      this.subClicks(special_name);
      this.$router.push({
        path: "/hotDetail",
        name: "hotDetail",
        query: {
          isHot: this.activeStyle,
          special_name: special_name,
          video_index: 0,
        },
      });
    },

    activeClick(value) {
      let params = { page: 1, video_name: this.video_name };
      this.reset();
      this.mescrollUp.page.num = 1;
      if (this.product_type !== "") {
        params.product_type = this.product_type;
      }
      if (value === 0) {
        if (this.class_type !== "") {
          params.class_type = this.class_type;
        }
      }
      if (this.pay_type !== "") {
        params.pay_type = this.pay_type;
      }
      if (value == 0) {
        this.activeStyle = true;
      } else {
        this.activeStyle = false;
      }
      this.dataList = [];
      this.getData(params, this.mescroll);
    },

    fadeModal() {
      if (this.visiable) {
        this.visiable = false;
      } else {
        this.visiable = true;
      }
    },

    clickButton(key, value) {
      if (key == "first") {
        this.product_type = value;
      } else if (key == "second") {
        this.class_type = value;
      } else {
        this.pay_type = value;
      }

      this.clickArr.forEach((item) => {
        if (item.key == key) {
          item.children.forEach((data) => {
            if (data.value == value) {
              data.active = true;
            } else {
              data.active = false;
            }
          });
        }
      });
    },

    reset() {
      this.class_type = "";
      this.pay_type = "";
      this.product_type = "";
      this.clickArr.forEach((item) => {
        item.children.forEach((data) => {
          data.active = false;
        });
      });
    },

    submit() {
      this.visiable = false;
      const params = { page: 1, video_name: this.video_name };
      this.mescrollUp.page.num = 1;
      if (this.product_type !== "") {
        params.product_type = this.product_type;
      }
      if (this.activeStyle == true) {
        if (this.class_type !== "") {
          params.class_type = this.class_type;
        }
      }
      if (this.pay_type !== "") {
        params.pay_type = this.pay_type;
      }
      this.dataList = [];
      this.getData(params, this.mescroll);
    },

    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },

    getData(params, mescroll) {
      mescroll.showUpScroll();
      this.$request
        .handleFetchPostJson(
          this.activeStyle ? classApi.video_list : classApi.subjects,
          params
        )
        .then((res) => {
          if (res.video_list || res.subject_list) {
            this.dataList = this.dataList.concat(
              this.activeStyle ? res.video_list : res.subject_list
            );
          }
          this.$nextTick(() => {
            mescroll.endSuccess(
              this.activeStyle
                ? res.video_list && res.video_list.length
                : res.subject_list && res.subject_list.length
            );
          });
        });
    },

    upCallback(page, mescroll) {
      const params = { page: page.num, video_name: this.video_name };
      if (this.product_type !== "") {
        params.product_type = this.product_type;
      }
      if (this.activeStyle) {
        if (this.class_type !== "") {
          params.class_type = this.class_type;
        }
      }
      if (this.pay_type !== "") {
        params.pay_type = this.pay_type;
      }
      this.getData(params, mescroll);
    },
  },
};
</script>
