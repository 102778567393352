<template>
  <div
    class="class-popup-component"
    :style="{ display: visiable ? 'block' : 'none' }"
  >
    <div class="cp-component-detail">
      <div class="cpc-detail-content">
        <div
          :key="item.key"
          class="cpcd-content-list"
          v-for="item in state.clickList"
        >
          <div
            class="cpcdc-list-title"
            :style="{
              display: activeStyle
                ? 'block'
                : item.name == '课程类型'
                ? 'none'
                : 'block',
            }"
          >
            {{ item.name }}
          </div>
          <div
            class="cpcdc-list-subcontent"
            :style="{
              display: activeStyle
                ? 'block'
                : item.name == '课程类型'
                ? 'none'
                : 'block',
            }"
          >
            <span
              :key="data.name"
              v-for="data in item.children"
              @click="clickButton(item.key, data.value)"
              :style="{ color: data.active ? '#1D6AF2' : '#999999' }"
            >
              {{ data.name }}
            </span>
          </div>
        </div>
      </div>

      <div class="cp-component-button">
        <div class="cpc-button-reset" @click="reset">重置</div>
        <div class="cpc-button-submit" @click="submit">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { clickList } from "../../tools/config";
export default {
  name: "ClassCondition",
  props: {
    visiable: {
      type: Boolean,
      default: false,
    },
    activeStyle: {
      type: Boolean,
      default: true,
    },
    clickButton: {
      type: Function,
      default: function () {},
    },
    reset: {
      type: Function,
      default: function () {},
    },
    submit: {
      type: Function,
      default: function () {},
    },
  },
  setup() {
    const state = reactive({
      clickList,
    });
    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped>
.class-popup-component {
  display: none;
  position: absolute;
  top: 110px;
  width: 100%;
  height: calc(100vh - 160px);
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);

  .cp-component-detail {
    position: absolute;
    // top: 108px;
    background: #fff;
    z-index: 1000;
    width: 100%;
  }

  .cpc-detail-content {
    padding-bottom: 60px;
  }

  .cpcd-content-list {
    padding: 0 12px;
    margin-bottom: 20px;
    font-size: 0;
  }

  .cpcdc-list-title {
    font-size: 0.12rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;
  }

  .cpcdc-list-subcontent {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      display: inline-block;
      height: 35px;
      background: #f7f7f7;
      text-align: center;
      line-height: 35px;
      width: calc(33.3vw - 16px);
      margin-bottom: 10px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      font-size: 0.14rem;
    }
    .other-span-big {
      width: 220px;
    }
    .other-span-small {
      width: 157px;
      margin-left: 0px !important;
    }
    span:nth-child(3n-1) {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .cp-component-button {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    div {
      width: 50%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      font-size: 0.17rem;
    }
    .cpc-button-submit {
      background: #1d6af2;
      color: #ffffff;
    }
    .cpc-button-reset {
      background: #f2f2f2;
      color: #999999;
    }
  }
}
</style>
