<template>
  <div class="class-tab-component">
    <div class="ct-component-left">
      <span
        :key="item"
        v-for="(item, index) in state.titleArr"
        :class="`ctc-left-common ${
          (!index && activeStyle) || (index && !activeStyle)
            ? ' ctc-left-common--active'
            : ''
        }`"
        @click="activeClick(index)"
      >
        {{ item }}
      </span>
    </div>
    <div class="ct-component-right" @click="fadeModal">
      <div class="ctc-right-icon">
        <IconFontSymbol icon="iconfilter" :style="state.iconStyle" />
      </div>
      <div class="ctc-right-txt">筛选</div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import IconFontSymbol from "@/components/IconFontSymbol";

const iconStyle = { "font-size": "0.14rem" };
export default {
  name: "ClassTab",
  props: {
    activeStyle: {
      type: Boolean,
      default: true,
    },
    activeClick: {
      type: Function,
      default: function () {},
    },
    fadeModal: {
      type: Function,
      default: function () {},
    },
  },
  components: {
    IconFontSymbol,
  },
  setup() {
    const state = reactive({
      iconStyle,
      titleArr: ["热门课程", "专题课程"],
    });
    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped>
.class-tab-component {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding-bottom: 20px;
  font-size: 0;
  .ct-component-left {
    font-size: 0.17rem;

    .ctc-left-common {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
    }

    .ctc-left-common--active {
      font-weight: 600;
      color: #1d6af2;
    }

    span:nth-child(1) {
      margin-right: 10px;
    }
  }

  .ct-component-right {
    display: flex;
    align-items: center;
    .ctc-right-icon,
    .ctc-right-txt {
      height: 26px;
      width: 35px;
    }
    .ctc-right-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      border-radius: 4px 0px 0px 4px;
      background: #e4f1ff;
    }
    .ctc-right-txt {
      font-size: 0.12rem;
      color: #53a9ff;
      line-height: 26px;
      text-align: center;
      border-radius: 0px 4px 4px 0px;
      background: #f1f8ff;
    }
  }
}
</style>
