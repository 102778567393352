<template>
  <van-form @submit="onSubmit">
    <van-field v-model="video_name" :placeholder="placeholder">
      <template #right-icon>
        <IconFontSymbol :icon="'iconsearch'" :style="state.iconStyle" />
      </template>
    </van-field>
  </van-form>
</template>
<script>
import { reactive } from "vue";
import IconFontSymbol from "@/components/IconFontSymbol.vue";

const iconStyle = { "font-size": "0.14rem" };
export default {
  name: "ClassInput",
  props: {
    video_name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "微生物多样性分析课程",
    },
    onSubmit: {
      type: Function,
      default: function () {},
    },
  },
  components: {
    IconFontSymbol,
  },
  setup() {
    const state = reactive({
      iconStyle,
    });

    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped></style>
